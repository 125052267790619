/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import * as masterActions from '@/actions/master';
import Layout from '@/components/layout';
import SEO from '@/components/seo';

type Props = {
  requestProcess: void;
  completeProcess: void;
  isProcessing: boolean;
  data: any;
  wpModel: any;
  classes: {
    root: string;
    componentWrapper: string;
    body: string;
    title: string;
  };
};

class PrivacyPage extends Component<Props> {
  render() {
    const { data, classes } = this.props;

    return (
      <Layout>
        <SEO title="プライバシーポリシー" />
        <div className={classes.root}>
          <div className={classes.componentWrapper}>
            <section id="privacy pt20">
              <div className="title-wrapper">
                <h1 className={classNames('title', classes.title)}>
                  {data.wordpressPage.title}
                </h1>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
              />
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: '0 auto',
    maxWidth: 980,
    padding: '40px 80px',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px',
    },
  },
  body: {
    maxWidth: 834,
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: 1.6,
    color: '#808080',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  title: {
    fontFamily: 'Sawarabi Mincho',
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px !important',
    },
  },
});

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "プライバシーポリシー" }) {
      id
      content
      title
    }
  }
`;

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master;

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  };
}

const connector = connect(mapStateToProps, { ...masterActions });

export default compose(pure, withStyles(styles), connector)(PrivacyPage);
